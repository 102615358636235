<template>
  <div class="d-flex justify-center align-center fill-height">
    <div class="ForgetBox pt-10 ">
      <div>
        <v-form ref="resetPassword" @submit.prevent="resetPassword" @keypress.native.enter="resetPassword">
          <h3 class="text-center">{{ $t('resetPassword.title') }} </h3>
          <v-text-field :rules="requiredRules" v-model="password" dense :label="$t('passwordLabel')" class="mt-8"
                        outlined></v-text-field>
          <v-text-field :rules="passwordConfirmRules" v-model="password_confirmation" dense
                        :label="$t('passwordConfirmationLabel')"
                        outlined></v-text-field>
          <v-btn color="primary" block elevation="0" @click="resetPassword">
            {{ $t('confirm') }}
          </v-btn>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      password_confirmation: null,
      password: null,
    }
  },
  methods: {
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        this.$emitEvent('setLoading', true)
        this.requesting('auth', 'ChangePassword', '', {
          token: this.$route.params.token,
          password: this.password,
          password_confirmation: this.password_confirmation
        }).then(() => {
          this.$emitEvent('notify', {title: this.$t('notify.passwordChange'), color: 'green'})
          this.attachTelegramAccount()
          this.$router.push({name: 'login'})
        }).catch(() => {
          this.$router.push({name: 'login'})
        }).finally(() => {
          this.$emitEvent('setLoading', false)
        })
      }
    },
  },
  computed: {
    
  }
}
</script>

<style scoped>
.ForgetBox {
  width: 400px;
  height: 400px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  padding: 20px;
  border-radius: 10px;
}
</style>